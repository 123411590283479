.minimap-container {
    position: relative;
    width: 181px;
    height: 165px;
    overflow: hidden;
}

.minimap-container .compass {
    cursor: pointer;
    clip-path: circle(18px);
    position: absolute;
    left: -4px;
    top: -4px;
}

.minimap-container .worldmap-icon {
    cursor: pointer;
    position: absolute;
    left: 144px;
    top: 128px;
    width: 32px;
    height: 32px;
    background-image: url(./worldmap-icon.png);
    background-size: 32px 32px;
}

.minimap-container .worldmap-icon:hover {
    background-image: url(./worldmap-icon-hover.png);
}

.minimap-container .minimap {
    clip-path: circle(77px at 384px 384px);
    position: absolute;
    left: -285px;
    top: -301px;
    transform-origin: 384px 384px;
    background-color: black;
}

.minimap-images {
    position: relative;
}

.minimap-image {
    position: absolute;
    padding: 0;
    margin: 0;
}
