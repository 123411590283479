.overlay-container {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.hud.left-top {
    position: absolute;
    padding: 10px;
}
.hud.right-top {
    position: absolute;
    padding: 10px;
    right: 0;
}

.joystick-container {
    position: absolute;
    bottom: 0;
    padding: 40px;
}

.joystick-container.left {
    left: 0;
}

.joystick-container.right {
    right: 0;
}
