@font-face {
    font-family: "OSRS Bold";
    src: url(./media/RuneScape-Bold-12.ttf);
}
@font-face {
    font-family: "OSRS Small";
    src: url(./media/RuneScape-Plain-11.ttf);
}

html,
body {
    margin: 0;
    padding: 0;
    height: 100vh;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    background-color: black;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

#root,
.max-height {
    height: 100%;
}

canvas {
    outline: none;
    border: none;
}

.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-text {
    font-family: ui-monospace, SFMono-Regular, Menlo, "Roboto Mono", monospace;
    font-weight: bold;
    color: white;
    text-shadow: 1px 1px 0 black;
}

.flex {
    display: flex;
    flex: 1;
}

.align-right {
    justify-content: right;
}

.rs-border {
    border: 6px solid;
    border-image: url(./media/interface-border.png) 6 / 6px / 0px repeat;
}

.rs-background {
    background-image: url(./media/interface-bg.png);
}
