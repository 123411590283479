.loading-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 305px;
    height: 35px;
    font-family: ui-monospace, SFMono-Regular, Menlo, "Roboto Mono", monospace;
    /* font-family: Helvetica; */
    font-size: 13px;
    font-weight: bold;
    color: white;
    background-color: black;
    box-sizing: border-box;
    border: solid #8c1111 1px;
}

.loading-bar-text {
    display: flex;
    z-index: 1;
}

.loading-bar-progress-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.loading-bar-progress {
    border: solid black 1px;
    box-sizing: border-box;
    background-color: #8c1111;
    /* width: 75%; */
    height: 100%;
}
